import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader, Collapse } from "reactstrap";
import ProfitLossCards from "./ProfitLossCards";
import DashboardDetail from "./DashboardDetail";
import { Breadcrumbs } from "../../../AbstractElements";
import { H2, H4, H5 } from "../../../AbstractElements";
import { useSelector, useDispatch } from "react-redux";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import { FETCH_CLIENT_TRANSACTIONS_ANALYSIS } from "../../../_helper/url_helper";
import { fetchOwner } from "../../../store/login/action";

const GeneralComponent = () => {
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  const [data, setData] = useState({ "USD": {}, "INR": {} });
  const [filters, setFilters] = useState({ startDate: "", endDate: "" });
  const [isOpen, setIsOpen] = useState(false);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));

  useEffect(() => {
    fetchDataHandler();
    dispatch(fetchOwner({ jwttoken: loginState.jwttoken }));
  }, []);

  useEffect(() => {
    if (loginState.owner.status === "inactive") {
      localStorage.removeItem("jwttoken");
      window.location.href = "/";
    }
  }, [loginState]);

  // useEffect(() => {
  //   let interval = setInterval(fetchDataHandler, 5000);
  //   return () => { clearInterval(interval) }
  // }, []);

  async function fetchDataHandler() {
    try {
      let data = {};
      if (filters.startDate) {
        data["start_date"] = filters.startDate;
      }

      if (filters.endDate) {
        data["end_date"] = filters.endDate;
      }

      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_CLIENT_TRANSACTIONS_ANALYSIS, { params: data });
      if (res.data.status === "SUCCESS") {
        setData(res.data.payload);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Fragment>
      <Container fluid={true} className="general-widget">

        <Row>
          <Col>
            <Card className="mb-4">
              <CardHeader
                className="bg-secondary py-3"
                onClick={() => setIsOpen(!isOpen)}
              >
                <h5 className="d-flex justify-content-between">
                  <span>Filters</span>
                  {isOpen ? (
                    <i className="icofont icofont-rounded-up"></i>
                  ) : (
                    <i className="icofont icofont-rounded-down"></i>
                  )}
                </h5>
              </CardHeader>
              <Collapse isOpen={isOpen}>
                <CardBody>
                  <div className="row">
                    {/* <div className="col">
                          <label className="col-form-label form-label">
                            Search Script
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Script"
                          />
                        </div> */}

                    <div className="col">
                      <label className="col-form-label form-label">
                        StartDate
                      </label>
                      <input type="date" value={filters.startDate} className="form-control" onChange={(e) => { setFilters({ ...filters, startDate: e.target.value }) }} />
                    </div>

                    <div className="col">
                      <label className="col-form-label form-label">
                        EndDate
                      </label>
                      <input type="date" placeholder="Enter script" value={filters.endDate} className="form-control" onChange={(e) => { setFilters({ ...filters, endDate: e.target.value }) }} />
                    </div>

                    <div className="col d-flex align-items-end">
                      <button className="btn btn-primary" onClick={() => { fetchDataHandler() }}>Submit</button>
                    </div>

                    {/* <div className="col row align-items-end">
                          <div>
                            <button className="btn btn-danger m-t-5">
                              Reset
                            </button>
                          </div>
                        </div> */}
                    <div className="col"></div>
                  </div>
                </CardBody>
              </Collapse>
            </Card>
          </Col>
        </Row>
        {/* <Row>
          <ProfitLossCards data={data} />
        </Row> */}
        {/* <Row>
          <DashboardDetail />
        </Row> */}

        <Row>
          <Col sm="6" xl="4" lg="6">
            <div className="card-absolute card">
              <div className="bg-primary card-header">
                <h5>
                  <i className="fa fa-list-ul"></i> DEPOSIT INR
                </h5>
              </div>
              <div className="card-body fw-semibold">
                <h5>Total</h5>
                <p className="">₹{parseFloat(data["INR"].total_deposit).toFixed(2)}</p>
                <p className={"b-b-light m-t-5 m-b-5"}></p>
                <h5>SEAMLESS</h5>
                <p className="">₹{parseFloat(data["INR"].total_deposit_floxy).toFixed(2)}</p>
                <p className={"b-b-light m-t-5 m-b-5"}></p>
                <h5>Manual</h5>
                <p>₹{parseFloat(data["INR"].total_deposit_manual).toFixed(2)}</p>
                <p className={"b-b-light m-t-5 m-b-5"}></p>
                <h5>Bonus</h5>
                <p>₹{parseFloat(data["INR"].total_bonus).toFixed(2)}</p>
                <p className={"b-b-light m-t-5 m-b-5"}></p>
                <h5>Transactions</h5>
                <p>₹{parseFloat(data["INR"].total_depsosit_transactions).toFixed(2)}</p>
                <p className={"b-b-light m-t-5 m-b-5"}></p>
                <h5>Highest Deposit</h5>
                <p>₹{parseFloat(data["INR"].highest_deposit).toFixed(2)}</p>
              </div>
            </div>
          </Col>


          <Col sm="6" xl="4" lg="6">
            <div className="card-absolute card">
              <div className="bg-primary card-header">
                <h5>
                  <i className="fa fa-list-ul"></i> DEPOSIT USD
                </h5>
              </div>
              <div className="card-body fw-semibold">
                <h5>Total</h5>
                <p className="">${parseFloat(data["USD"].total_deposit).toFixed(2)}</p>
                <p className={"b-b-light m-t-5 m-b-5"}></p>
                <h5>SEAMLESS</h5>
                <p className="">${parseFloat(data["USD"].total_deposit_floxy).toFixed(2)}</p>
                <p className={"b-b-light m-t-5 m-b-5"}></p>
                <h5>Manual</h5>
                <p>${parseFloat(data["USD"].total_deposit_manual).toFixed(2)}</p>
                <p className={"b-b-light m-t-5 m-b-5"}></p>
                <h5>Bonus</h5>
                <p>${parseFloat(data["USD"].total_bonus).toFixed(2)}</p>
                <p className={"b-b-light m-t-5 m-b-5"}></p>
                <h5>Transactions</h5>
                <p>${parseFloat(data["USD"].total_depsosit_transactions).toFixed(2)}</p>
                <p className={"b-b-light m-t-5 m-b-5"}></p>
                <h5>Highest Deposit</h5>
                <p>${parseFloat(data["USD"].highest_deposit).toFixed(2)}</p>
              </div>
            </div>
          </Col>

          <Col sm="12" />

          <Col sm="6" xl="4" lg="6">
            <div className="card-absolute card">
              <div className="bg-primary card-header">
                <h5>
                  <i className="fa fa-list-ul"></i> WITHDRAW INR
                </h5>
              </div>
              <div className="card-body fw-semibold">
                <h5>Total</h5>
                <p className="">₹{parseFloat(data["INR"].total_withdraw).toFixed(2)}</p>
                <p className={"b-b-light m-t-5 m-b-5"}></p>
                <h5>SEAMLESS</h5>
                <p className="">₹{parseFloat(data["INR"].total_withdraw_floxy).toFixed(2)}</p>
                <p className={"b-b-light m-t-5 m-b-5"}></p>
                <h5>Manual</h5>
                <p>₹{parseFloat(data["INR"].total_withdraw_manual).toFixed(2)}</p>
                <p className={"b-b-light m-t-5 m-b-5"}></p>
                <h5>Transactions</h5>
                <p>₹{parseFloat(data["INR"].total_withdraw_transactions).toFixed(2)}</p>
                <p className={"b-b-light m-t-5 m-b-5"}></p>
                <h5>Highest Deposit</h5>
                <p>₹{parseFloat(data["INR"].highest_withdraw).toFixed(2)}</p>
              </div>
            </div>
          </Col>



          <Col sm="6" xl="4" lg="6">
            <div className="card-absolute card">
              <div className="bg-primary card-header">
                <h5>
                  <i className="fa fa-list-ul"></i> WITHDRAW USD
                </h5>
              </div>
              <div className="card-body fw-semibold">
                <h5>Total</h5>
                <p className="">${parseFloat(data["USD"].total_withdraw).toFixed(2)}</p>
                <p className={"b-b-light m-t-5 m-b-5"}></p>
                <h5>SEAMLESS</h5>
                <p className="">${parseFloat(data["USD"].total_withdraw_floxy).toFixed(2)}</p>
                <p className={"b-b-light m-t-5 m-b-5"}></p>
                <h5>Manual</h5>
                <p>${parseFloat(data["USD"].total_withdraw_manual).toFixed(2)}</p>
                <p className={"b-b-light m-t-5 m-b-5"}></p>
                <h5>Transactions</h5>
                <p>${parseFloat(data["USD"].total_withdraw_transactions).toFixed(2)}</p>
                <p className={"b-b-light m-t-5 m-b-5"}></p>
                <h5>Highest Deposit</h5>
                <p>${parseFloat(data["USD"].highest_withdraw).toFixed(2)}</p>
              </div>
            </div>
          </Col>


          <Col sm="12" />

          <Col sm="6" xl="4" lg="6">
            <div className="card-absolute card">
              <div className="bg-primary card-header">
                <h5>
                  <i className="fa fa-list-ul"></i> TRANSACTIONS INR
                </h5>
              </div>
              <div className="card-body fw-semibold">
                <h5>Completed</h5>
                <p className="">{data["INR"].completed}</p>
                <p className={"b-b-light m-t-5 m-b-5"}></p>
                <h5>Canceled</h5>
                <p className="">{data["INR"].cancel}</p>
              </div>
            </div>
          </Col>

          <Col sm="6" xl="4" lg="6">
            <div className="card-absolute card">
              <div className="bg-primary card-header">
                <h5>
                  <i className="fa fa-list-ul"></i> TRANSACTIONS USD
                </h5>
              </div>
              <div className="card-body fw-semibold">
                <h5>Completed</h5>
                <p className="">{data["USD"].completed}</p>
                <p className={"b-b-light m-t-5 m-b-5"}></p>
                <h5>Canceled</h5>
                <p className="">{data["USD"].cancel}</p>
              </div>
            </div>
          </Col>
        </Row>
        {/* <Row>
        <DashboardDetail />
        </Row> */}

      </Container>
    </Fragment>
  );
};

export default GeneralComponent;
